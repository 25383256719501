/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { Suspense, lazy } from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import LazyLoadingErrorBoundary from 'utils/hocs/LazyLoadingErrorBoundary';
import { PAGES } from 'utils/constants/links';
import styles from 'style.module.css';

const Home = lazy(() =>
  import(/* webpackChunkName: 'home' */ 'pages/Home')
);

const Pattern = lazy(() =>
  import(/* webpackChunkName: 'pattern' */ 'pages/Pattern')
);

const App = () => (
  <div className={styles['app']}>
    <Suspense fallback='Loading...'>
      <LazyLoadingErrorBoundary>
        <Switch>
          <Route
            path={PAGES.PATTERN}
            component={Pattern} />
          <Route
            exact
            path={PAGES.HOME}
            component={Home} />
          <Route render={() => <Redirect to={{pathname: PAGES.HOME}} />} />
        </Switch>
      </LazyLoadingErrorBoundary>
    </Suspense>
  </div>
);

export default App;
